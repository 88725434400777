import React from 'react';
import { FeatureFlag, FeatureFlags } from 'utils/feature-flags';
import { Deposit as DepositV1 } from 'modules/2023-q3/deposit-withdrawal/deposit/deposit';
import { Deposit as DepositV2 } from 'modules/2024-q1/schedule-transfer/deposit-withdrawal/deposit/deposit';

export const DepositTransition = () => {
  return (
    <FeatureFlag
      disabled={<DepositV1 />}
      enabled={<DepositV2 />}
      flag={FeatureFlags.REACT_APP_2024_Q1_SCHEDULED_TRANSFERS}
    />
  );
};
