import React from 'react';
import { dayjs } from 'app/dayjs';
import { Box, Grid } from '@material-ui/core';
import {
  Currency,
  CurrencyVariant,
  Typography,
  TypographyVariant,
} from 'components/core';
import { SectionContainer, SectionTitle } from 'modules/dashboard/components';
import { useBusiness } from 'hooks/business/use-business';
import { usePortfolios } from 'modules/portfolio/allocation/hooks/use-portfolios';
import { useAggregateBalances } from 'hooks/portal-gateway/use-aggregate-balances';
import { useMonthlyReturns } from 'modules/dashboard/treasure-reserve/returns/hooks/use-monthly-returns';
import { useFees } from 'hooks/custodian/use-fees';
import { MonthlyReturn } from 'modules/dashboard/treasure-reserve/returns/components/returns-table/returns-table';
import { useDashboardActivity } from 'hooks/portal-gateway/use-dashboard-activity';
import { Transaction } from 'modules/dashboard/treasure-reserve/transactions/components/transactions-table/transactions-table';
import {
  useFeatureFlags,
  FeatureFlag,
  FeatureFlags,
} from 'utils/feature-flags';

import { Greeting } from './components/greeting/greeting';

import { Styled } from './overview.style';

const OverviewNumber = ({ label, value }: { label: string; value: number }) => {
  return (
    <>
      <Typography variant={TypographyVariant.Paragraph2}>{label}</Typography>

      <Typography variant={TypographyVariant.HeaderRegular}>
        <Currency number={value} variant={CurrencyVariant.Full} />
      </Typography>
    </>
  );
};

export const Overview = () => {
  const { data: business, isLoading: isLoadingBusiness } = useBusiness();
  const { data: portfolios, isLoading: isLoadingPortfolios } = usePortfolios(
    business?.Id,
  );
  const { data: monthlyReturns, isLoading: isLoadingMonthlyReturns } =
    useMonthlyReturns();
  const { isFeatureFlagEnabled } = useFeatureFlags();
  const { data: aggregateBalances, isLoading: isLoadingAggregateBalances } =
    useAggregateBalances(business?.Id);
  const { data: fees, isLoading: isLoadingFees } = useFees(business?.Id);

  // filter out partial fees
  const feesToDisplay = fees?.filter(
    (fee: { parentfee: string }) => fee.parentfee === null,
  );

  // sort the fees newest to oldest
  feesToDisplay?.sort((a: { calcDate: string }, b: { calcDate: string }) =>
    a.calcDate > b.calcDate ? -1 : 1,
  );

  // sort the returns newest to oldest
  monthlyReturns?.data?.sort((a: { date: string }, b: { date: string }) =>
    a.date > b.date ? -1 : 1,
  );

  // TODO: temporarily use transactions data while account balances gets fixed
  const { data: dashboardActivity, isLoading: isLoadingDashboardActivity } =
    useDashboardActivity();
  const pendingTransactions = dashboardActivity?.transactions?.filter(
    (transaction: Transaction) =>
      transaction.status === 'IN_PROGRESS' || transaction.status === 'PENDING',
  );

  const totalBalance = isFeatureFlagEnabled(
    FeatureFlags.REACT_APP_2023_Q3_AGGREGATE_BALANCES_ON_DASH,
  )
    ? aggregateBalances?.aum?.total?.amount
    : portfolios?.amountsInDollars?.totalAmount;
  const pendingDeposits = pendingTransactions?.reduce(
    (sum: number, pendingTransaction: Transaction) =>
      sum +
      (pendingTransaction.description.includes('into')
        ? Number(pendingTransaction.amount)
        : 0),
    0,
  );
  const pendingWithdrawals = pendingTransactions?.reduce(
    (sum: number, pendingTransaction: Transaction) =>
      sum +
      (pendingTransaction.description.includes('out')
        ? Number(pendingTransaction.amount)
        : 0),
    0,
  );
  const lastMonthLabel = dayjs(monthlyReturns?.data[0].date).format('MMM');
  const lastMonthEarnings =
    monthlyReturns?.data[0].cash.totalReturns +
    monthlyReturns?.data[0].investments.totalReturns;

  const latestFeeMonthLabel = dayjs(
    feesToDisplay?.[0]?.calcDate?.substr(0, 10),
  ).format('MMM');

  if (
    isLoadingBusiness ||
    isLoadingDashboardActivity ||
    isLoadingFees ||
    isLoadingMonthlyReturns ||
    isLoadingPortfolios ||
    isLoadingAggregateBalances
  ) {
    return null;
  }

  return (
    <>
      <Box mb={6}>
        <Greeting />
      </Box>

      <Grid container spacing={4}>
        <Grid item>
          <Box mr={8}>
            <Typography variant={TypographyVariant.Body}>
              Balance Total
            </Typography>

            <Typography variant={TypographyVariant.LargeHeader}>
              <Currency number={totalBalance} variant={CurrencyVariant.Full} />
            </Typography>

            {/*<Grid container spacing={2}>
              <Grid item>
                <Typography
                  color="grey4"
                  variant={TypographyVariant.Paragraph2}
                >
                  {' '}
                  <Currency
                    number={availableBalance}
                    variant={CurrencyVariant.Full}
                  />{' '}
                  Available
                </Typography>
              </Grid>
              <Grid item>
                <Typography
                  color="grey4"
                  variant={TypographyVariant.Paragraph2}
                >
                  <Currency
                    number={pendingBalance}
                    variant={CurrencyVariant.Full}
                  />{' '}
                  Pending
                </Typography>
              </Grid>
            </Grid>*/}
          </Box>
        </Grid>

        <Grid item>
          <OverviewNumber label="Pending Deposits" value={pendingDeposits} />
        </Grid>

        <Grid item>
          <OverviewNumber
            label="Pending Withdrawals"
            value={pendingWithdrawals}
          />
        </Grid>

        {monthlyReturns?.data[0] && (
          <Grid item>
            <OverviewNumber
              label={`${lastMonthLabel} Returns`}
              value={lastMonthEarnings}
            />
          </Grid>
        )}

        <FeatureFlag
          enabled={
            <>
              {feesToDisplay.length && (
                <Grid item>
                  <OverviewNumber
                    label={`${latestFeeMonthLabel} Fee`}
                    value={feesToDisplay?.[0].amount}
                  />
                </Grid>
              )}
            </>
          }
          flag={FeatureFlags.REACT_APP_2024_Q2_SHOW_FEES}
        />
      </Grid>
    </>
  );
};
