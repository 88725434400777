import React from 'react';
import {
  i18nNamespace,
  IDepositWithdrawal,
  DepositWithdrawalVariant,
} from 'modules/2023-q3/deposit-withdrawal/interface/deposit-withdrawal';
import { useTranslation, Trans } from 'app/i18n';
import { Box, Grid } from '@material-ui/core';
import {
  Currency,
  CurrencyVariant,
  Spinner,
  Typography,
  TypographyVariant,
} from 'components/core';
import { FormContainer } from 'modules/2023-q3/components/forms/components/form-container/form-container';
import { Button } from 'modules/2023-q3/components/button/button';
import { Size, TreasureFiEmails } from 'enums';
import { ProductAllocation } from 'modules/2023-q3/deposit-withdrawal/pages/review/components/product-allocation/product-allocation';
import { useBusiness } from 'hooks/business/use-business';
import { usePortfolios } from 'modules/portfolio/allocation/hooks/use-portfolios';
import { ContactTreasureSupportLink } from 'modules/support/components/contact-treasure-support-link/contact-treasure-support-link';
import { Link } from 'react-router-dom';
import { RoutesPath } from 'routes/constants/routes-path';
import { MobileView } from 'modules/2023-q3/components/mobile-view/mobile-view';
import {
  ButtonContent,
  SupportedIcons,
} from 'modules/2023-q3/components/button/button-content/button-content';
import { BankAccount } from 'types';
import { EstimatedArrivalBoxes } from 'modules/2023-q3/deposit-withdrawal/withdrawal/components/estimated-arrival-boxes/estimated-arrival-boxes';
import { useHideProduct } from 'modules/2023-q4/white-label/hooks/use-hide-product';
import { ProductAllocationProducts } from 'modules/2023-q3/allocation/interface/product-allocation';
import { SelectSwitchOption } from 'modules/2023-q3/components/forms/components/select-switch/select-switch';
import { dayjs } from 'app/dayjs';
import isToday from 'dayjs/plugin/isToday';
dayjs.extend(isToday);

interface ReviewProps extends IDepositWithdrawal {
  amount: number | undefined;

  bank: BankAccount | undefined;

  frequency: SelectSwitchOption;

  nextStep: () => void;

  previousStep: () => void;

  startDate: string;
}

export const Review: React.FC<ReviewProps> = ({
  amount,
  bank,
  frequency,
  nextStep,
  previousStep,
  startDate,
  variant,
}) => {
  const { t } = useTranslation(i18nNamespace);

  const { hideProduct } = useHideProduct();

  const isDeposit = variant === DepositWithdrawalVariant.Deposit;
  const DepositOrWithdrawal = t(isDeposit ? 'Deposit' : 'Withdrawal');

  const { data: business, isLoading: isLoadingBusiness } = useBusiness();
  const { data: portfolios, isLoading: isLoadingPortfolios } = usePortfolios(
    business?.Id,
  );

  const ContinueButton = ({ mobile }: { mobile?: boolean }) => (
    <Button
      borderRadius={mobile ? 0 : undefined}
      disabled={!(amount && amount > 0.0) || bank === undefined}
      fullWidth
      onClick={nextStep}
      bottomFixed={mobile}
    >
      <ButtonContent iconEnd={SupportedIcons.ArrowRight} mobile={mobile}>
        {t(mobile ? 'Confirm & Continue' : 'Confirm', { ns: 'app' })}
      </ButtonContent>
    </Button>
  );

  const ProductAllocations: React.FC = () => {
    const allocations = portfolios?.portfolios[0]?.allocations;

    const getPercentage = (type: string) => {
      return Number(
        allocations?.find(
          (allocation: { type: string }) => allocation.type === type,
        )?.percentage,
      );
    };

    const DisplayManagedProductAllocation = ({
      hasAllocation,
      product,
    }: {
      hasAllocation: boolean;
      product: ProductAllocationProducts;
    }) => {
      if (hideProduct(product)) {
        return null;
      }

      const getProductType = (product: ProductAllocationProducts) => {
        switch (product) {
          case ProductAllocationProducts.ManagedMoneyMarket:
            return 'MONEY_MARKET';
          case ProductAllocationProducts.ManagedTreasuries:
            return 'TBILL';
          case ProductAllocationProducts.ManagedIncome:
            return 'HIGH_YIELD';
        }

        return null;
      };

      const percentage = getProductType(product);

      return (
        <ProductAllocation
          percentage={
            hasAllocation && percentage ? getPercentage(percentage) : 0
          }
          productColor={`product${product.replace(/\s/g, '')}`}
          productName={product}
        />
      );
    };

    const managedProducts = [
      ProductAllocationProducts.ManagedMoneyMarket,
      ProductAllocationProducts.ManagedTreasuries,
      ProductAllocationProducts.ManagedIncome,
    ];

    if (isLoadingBusiness || isLoadingPortfolios) {
      return (
        <Box mt={8}>
          <Spinner />
        </Box>
      );
    }

    if (!allocations) {
      return (
        <>
          {managedProducts.map((product) => (
            <DisplayManagedProductAllocation
              hasAllocation={false}
              product={product}
            />
          ))}

          <ProductAllocation
            percentage={100}
            productColor="productCash"
            productName="Cash"
          />
        </>
      );
    }

    return (
      <>
        {managedProducts.map((product) => (
          <DisplayManagedProductAllocation hasAllocation product={product} />
        ))}

        <ProductAllocation
          percentage={getPercentage('CASH')}
          productColor="productCash"
          productName="Cash"
        />
      </>
    );
  };

  if (amount && bank) {
    const Amount: React.FC = () => {
      if (amount) {
        return (
          <Typography color="primary" variant={TypographyVariant.Paragraph2}>
            <Currency number={amount} variant={CurrencyVariant.Full} />
          </Typography>
        );
      }

      return null;
    };

    const Bank: React.FC = () => {
      if (bank) {
        // some bank account names begin with the bank institution name
        // this results in the bank name repeating twice (looks like a duplication error)
        // so this regex removes the bank name if it is the beginning of the accont name
        const regexBankName = new RegExp('^' + bank.institution.name);

        return (
          <Typography color="primary" variant={TypographyVariant.Paragraph2}>
            {`${bank.institution.name} ${bank.Name.replace(
              regexBankName,
              '',
            )} (${bank.mask})`}
          </Typography>
        );
      }

      return null;
    };

    const ContactSupport: React.FC = () => {
      return (
        <ContactTreasureSupportLink
          subject={isDeposit ? 'Custom Allocation' : 'Custom Withdrawal'}
        >
          <Typography color="blue2" variant={TypographyVariant.Paragraph2}>
            {TreasureFiEmails.Support}
          </Typography>
        </ContactTreasureSupportLink>
      );
    };

    const PortfolioAllocationLink: React.FC = () => {
      return <Link to={RoutesPath.tasks.allocation.path}>update it here</Link>;
    };

    const StartDate: React.FC = () => {
      const isToday = dayjs(startDate).isToday();

      if (isToday) {
        return <></>;
      }

      return (
        <>{` ${isDeposit ? 'after' : 'starting'} ${dayjs(startDate).format(
          'MMMM DD, YYYY',
        )}`}</>
      );
    };

    return (
      <FormContainer size={Size.Medium}>
        <Box mb={3}>
          <Typography color="black19" variant={TypographyVariant.Header3}>
            {t('Review & Confirm', { DepositOrWithdrawal })}
          </Typography>
        </Box>

        <Typography color="grey1" variant={TypographyVariant.Paragraph2}>
          <Trans
            t={t}
            i18nKey={
              isDeposit
                ? 'Deposit review scheduled'
                : 'Withdrawal review scheduled'
            }
            components={{
              amount: <Amount />,
              bank: <Bank />,
              frequency: (frequency.label as string).toLowerCase(),
              portfolioAllocationLink: <PortfolioAllocationLink />,
              startDate: <StartDate />,
              supportEmail: <ContactSupport />,
            }}
          />
        </Typography>

        {isDeposit ? (
          <Box mt={{ xs: 0, sm: 5 }}>
            <ProductAllocations />
          </Box>
        ) : (
          <Box mt={5}>
            <EstimatedArrivalBoxes amount={amount} />
          </Box>
        )}

        <Box mt={15}>
          <MobileView mobile={<ContinueButton mobile />}>
            <Grid container spacing={2}>
              <Grid item xs>
                <Button fullWidth onClick={previousStep}>
                  <ButtonContent iconStart={SupportedIcons.ArrowLeft}>
                    {t('Previous', { ns: 'app' })}
                  </ButtonContent>
                </Button>
              </Grid>

              <Grid item xs>
                <ContinueButton />
              </Grid>
            </Grid>
          </MobileView>
        </Box>
      </FormContainer>
    );
  }

  return null;
};
