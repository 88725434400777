import React from 'react';
import { Box, Grid } from '@material-ui/core';
import { Typography, TypographyVariant } from 'components/core';
import { Person } from 'modules/onboarding/v3-streamlined-onboarding/types/person';
import { PersonVariant } from 'modules/onboarding/v3-streamlined-onboarding/enums';
import { Styled } from './person-row.style';

interface PersonRowProps {
  editPerson: (person: Person) => void;

  person: Person;

  register: any;

  removePerson: () => void;

  resetMinimumError: (variant: PersonVariant) => void;

  variant: PersonVariant;
}

export const PersonRow: React.FC<PersonRowProps> = ({
  editPerson,
  person,
  register,
  removePerson,
  resetMinimumError,
  variant,
}) => {
  const showOwnerPercentageMessage =
    variant === PersonVariant.Owner && person.ownershipPercentage < 25;

  const handleRemovePerson = () => {
    removePerson();
  };

  return (
    <Styled.Container px={2} py={1}>
      <Grid container alignItems="center" justifyContent="space-between">
        <Grid item xs>
          <Typography color="nero" variant={TypographyVariant.Body}>
            {`${person.firstName} ${person.lastName}`}
          </Typography>
        </Grid>

        <Grid item>
          <Styled.Link
            $error={showOwnerPercentageMessage}
            onClick={() => editPerson(person)}
          >
            <Box px={1.75} py={0.5}>
              <Typography
                color={showOwnerPercentageMessage ? 'amaranth' : 'primary'}
                variant={TypographyVariant.LargerLabel}
              >
                {showOwnerPercentageMessage ? 'Add owner percentage' : 'Edit'}
              </Typography>
            </Box>
          </Styled.Link>
        </Grid>

        <Grid item xs={1}>
          <Box onClick={handleRemovePerson} textAlign="right">
            <Styled.Close />
          </Box>
        </Grid>
      </Grid>
    </Styled.Container>
  );
};
