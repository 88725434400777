import {
  dateMask,
  einMask,
  phoneNumberMask,
  ssnMask,
  USAZipCodeMask,
} from 'utils/forms/masks';

import {
  formattedSSNRegExp,
  EINRegExp,
  USAZipCodeRegExp,
} from 'utils/forms/regular-expressions';

import {
  isGreaterThan,
  isLessThan,
  isValidDateOfBirth,
  isValidEmail,
  isValidUSAPhoneNumber,
  maxAddressLength,
  maxCompanyNameLength,
  maxEmailLength,
  maxJobTitleLength,
  maxNonUSAStateLength,
  maxNonUSAZipCodeLength,
  maxNameLength,
  maxNonUSANationalIdentifierLength,
  maxUSAStateLength,
  maxUSAZipCodeLength,
  DEFAULT_MAX_TEXTAREA_LENGTH,
} from 'utils/forms/validators';

import {
  BeneficialOwnershipExemptionStatus,
  Country,
  IndustryType,
  InvestmentObjective,
  TaxClassification,
} from 'enums';

import { AutocompleteSpecs } from 'modules/onboarding/v3-streamlined-onboarding/pages/components/forms/autocomplete-controller/autocomplete-controller';
import { CheckboxFormFieldSpecs } from 'modules/onboarding/v3-streamlined-onboarding/pages/components/forms/checkbox-controller/checkbox-controller';
import { TextareaSpecs } from 'modules/onboarding/v3-streamlined-onboarding/pages/components/forms/textarea-controller/textarea-controller';
import { TextFormFieldSpecs } from 'modules/onboarding/v3-streamlined-onboarding/pages/components/forms/text-field-controller/text-field-controller';

export const MAX_ALLOWED_MONEY = 9_999_999_999.99;

export const countryOptions = Object.entries(Country).map(
  ([countryCode, countryName]) => {
    return { label: countryName, value: countryCode };
  },
);

type FormFields = {
  leaderOfPublicCompany: CheckboxFormFieldSpecs;
  brokerDealer: CheckboxFormFieldSpecs;
  largeTrader: CheckboxFormFieldSpecs;
  stockExchangeFinraMemberAssociated: CheckboxFormFieldSpecs;
  haveInterestedPartiesToReport: CheckboxFormFieldSpecs;
  politicallyExposed: CheckboxFormFieldSpecs;
  jobTitle: TextFormFieldSpecs;
  firstName: TextFormFieldSpecs;
  lastName: TextFormFieldSpecs;
  dateOfBirth: TextFormFieldSpecs;
  citizenshipCountry: AutocompleteSpecs;
  residencyCountry: AutocompleteSpecs;
  USANationalIdentifier: TextFormFieldSpecs;
  nonUSANationalIdentifier: TextFormFieldSpecs;
  city: TextFormFieldSpecs;
  address1: TextFormFieldSpecs;
  address2: TextFormFieldSpecs;
  USAState: Omit<AutocompleteSpecs, 'options'>; // these options are not static, they are fetched from the backend
  nonUSAState: TextFormFieldSpecs;
  USAZipCode: TextFormFieldSpecs;
  nonUSAZipCode: TextFormFieldSpecs;
  phoneNumber: TextFormFieldSpecs;
  businessCountry: AutocompleteSpecs;
  businessPhoneNumber: TextFormFieldSpecs;
  businessTypeId: Omit<AutocompleteSpecs, 'options'>; // options are not static, they are fetched from the backend;
  taxClassification: AutocompleteSpecs;
  industryType: AutocompleteSpecs;
  ein: TextFormFieldSpecs;
  annualGrossRevenue: TextFormFieldSpecs;
  totalLiquidAssets: TextFormFieldSpecs;
  investmentObjective: AutocompleteSpecs;
  exemptListingBeneficialOwners: CheckboxFormFieldSpecs;
  exemptListingBeneficialOwnersExemptionStatus: AutocompleteSpecs;
  foreignFinancialInstitution: CheckboxFormFieldSpecs;
  legalName: TextFormFieldSpecs;
  doingBusinessAs: TextFormFieldSpecs;
  businessDescription: TextareaSpecs;
  isAuthorizedSigner: CheckboxFormFieldSpecs;
  ownershipPercentage: TextFormFieldSpecs;
  email: TextFormFieldSpecs;
  accountAgreements: CheckboxFormFieldSpecs;
};

const autoCompletePlaceholder = 'Please select...';
const amountPlaceholder = 'Please enter an amount';
const percentagePlaceholder = 'Please enter a percentage';

export const formFields: FormFields = {
  legalName: {
    name: 'legalName',
    label: 'Legal business name',
    placeholder: 'Company',
    validations: {
      required: true,
      maxLength: maxCompanyNameLength,
    },
  },
  ownershipPercentage: {
    name: 'ownershipPercentage',
    label: 'Ownership percentage',
    percentage: true,
    placeholder: percentagePlaceholder,
    validations: {
      required: true,
      validate: (value) => {
        const errorMessage =
          'Ownership percentage must be between 10% and 100%';

        if (!isGreaterThan(value, 10, { orEqual: true })) {
          return errorMessage;
        }

        if (!isLessThan(value, 100, { orEqual: true })) {
          return errorMessage;
        }

        return true;
      },
    },
  },
  businessDescription: {
    name: 'businessDescription',
    label: 'Business description',
    validations: {
      required: true,
      maxLength: DEFAULT_MAX_TEXTAREA_LENGTH, // TODO: move textarea max length to the component?
    },
  },
  doingBusinessAs: {
    name: 'doingBusinessAs',
    label: 'Doing business as',
    validations: {
      maxLength: maxCompanyNameLength,
    },
  },
  businessCountry: {
    name: 'businessCountry',
    options: countryOptions,
    validations: {
      required: true,
    },
  },
  businessPhoneNumber: {
    name: 'phoneNumber',
    label: 'Business phone number',
    placeholder: '(###) ###-####',
    mask: phoneNumberMask,
    validations: {
      required: true,
      validate: (value: string) =>
        // if the phone number is valid, do not return an error
        // removes the red border on the input and clear the error message
        // if the number hasn't enough digits and has been already changed, return the error message
        isValidUSAPhoneNumber(value) ||
        'Business phone number must have 10 digits',
    },
  },
  businessTypeId: {
    name: 'businessTypeId',
    label: 'Type of business',
    placeholder: autoCompletePlaceholder,
    validations: { required: true },
  },
  taxClassification: {
    name: 'taxClassification',
    label: 'Tax classification',
    options: Object.entries(TaxClassification).map(([value, label]) => {
      return { label, value: label }; // backend expects the label as value
    }),
    placeholder: autoCompletePlaceholder,
    validations: { required: true },
  },
  industryType: {
    name: 'industryType',
    label: 'Industry',
    options: Object.entries(IndustryType).map(([value, label]) => {
      return { label, value: label }; // backend expects the label as value
    }),
    placeholder: autoCompletePlaceholder,
    validations: { required: true },
  },
  ein: {
    name: 'ein',
    label: 'Employer Identification Number (EIN)',
    placeholder: '12-3456789',
    mask: einMask,
    validations: { required: true, pattern: EINRegExp },
  },
  annualGrossRevenue: {
    name: 'annualGrossRevenue',
    label: 'Estimated annual gross revenue',
    currency: true,
    placeholder: amountPlaceholder,
    validations: {
      required: true,
      validate: (value) =>
        isLessThan(value, MAX_ALLOWED_MONEY, { orEqual: true }) ||
        'Annual Gross Revenue (Estimate) must be $9,999,999,999.99 or less',
    },
  },
  totalLiquidAssets: {
    name: 'totalLiquidAssets',
    label: 'Estimated total liquid assets',
    currency: true,
    placeholder: amountPlaceholder,
    validations: {
      required: true,
      validate: (value) =>
        isLessThan(value, MAX_ALLOWED_MONEY) ||
        'Total Liquid Assets (Estimate) must be $9,999,999,999.99 or less',
    },
  },
  investmentObjective: {
    name: 'investmentObjective',
    label: 'Investment objective',
    options: Object.entries(InvestmentObjective).map(([value, label]) => {
      return { label, value: label }; // backend expects the label as value
    }),
    placeholder: autoCompletePlaceholder,
    validations: { required: true },
  },
  exemptListingBeneficialOwners: {
    name: 'exemptListingBeneficialOwners',
    label: 'My company is exempt from listing beneficial owners',
    helperText:
      'This entity is subject to federal or state regulation and ownership info is available from those agencies.',
  },
  exemptListingBeneficialOwnersExemptionStatus: {
    name: 'exemptListingBeneficialOwnersExemptionStatus',
    label: 'Beneficial Ownership Exemption',
    options: Object.entries(BeneficialOwnershipExemptionStatus).map(
      ([value, label]) => {
        return { label, value };
      },
    ),
    placeholder: autoCompletePlaceholder,
    validations: { required: true },
  },
  foreignFinancialInstitution: {
    name: 'foreignFinancialInstitution',
    label: 'My company is a foreign financial institution',
    helperText:
      'The entity if not domiciled in the US and accepts deposits, holds financial assets for others, or is in the business of investing securities.',
  },
  isAuthorizedSigner: {
    name: 'isAuthorizedSigner',
    label:
      'I am authorized to open financial accounts and enter agreements on behalf of my company',
    validations: {
      required: true,
    },
  },
  accountAgreements: {
    name: 'accountAgreements',
    label: 'I agree to these account terms',
    validations: {
      required: true,
    },
  },
  phoneNumber: {
    name: 'phoneNumber',
    label: 'Phone number',
    placeholder: '(###) ###-####',
    mask: phoneNumberMask,
    validations: {
      required: true,
      validate: (value: string) =>
        // if the phone number is valid, do not return an error
        // removes the red border on the input and clear the error message
        // if the number hasn't enough digits and has been already changed, return the error message
        isValidUSAPhoneNumber(value) || 'Phone number must have 10 digits',
    },
  },
  email: {
    name: 'email',
    label: 'Email',
    validations: {
      required: true,
      maxLength: maxEmailLength,
      validate: (value: string) =>
        isValidEmail(value) || 'Please use a valid email address',
    },
  },
  address1: {
    errorLabel: 'Address',
    name: 'address1',
    placeholder: 'Address line 1',
    validations: {
      required: true,
      maxLength: maxAddressLength,
    },
  },
  address2: {
    name: 'address2',
    placeholder: 'Address line 2',
    validations: {
      maxLength: maxAddressLength,
    },
  },
  city: {
    name: 'city',
    placeholder: 'City',
    validations: {
      required: true,
      maxLength: maxAddressLength,
    },
  },
  USAState: {
    name: 'state',
    placeholder: 'State',
    validations: {
      required: true,
      maxLength: maxUSAStateLength,
    },
  },
  nonUSAState: {
    name: 'state',
    placeholder: 'State',
    validations: {
      required: true,
      maxLength: maxNonUSAStateLength,
    },
  },
  USAZipCode: {
    name: 'zipCode',
    placeholder: 'Zip',
    mask: USAZipCodeMask,
    validations: {
      required: true,
      pattern: USAZipCodeRegExp,
      maxLength: maxUSAZipCodeLength,
    },
  },
  nonUSAZipCode: {
    name: 'zipCode',
    placeholder: 'Zip',
    validations: {
      required: true,
      maxLength: maxNonUSAZipCodeLength,
    },
  },
  firstName: {
    name: 'firstName',
    label: 'Legal name',
    placeholder: 'First name',
    validations: {
      required: true,
      maxLength: maxNameLength,
    },
  },
  lastName: {
    name: 'lastName',
    placeholder: 'Last name',
    validations: {
      required: true,
      maxLength: maxNameLength,
    },
  },
  dateOfBirth: {
    name: 'dateOfBirth',
    label: 'Date of Birth',
    placeholder: 'MM/DD/YYYY',
    mask: dateMask,
    validations: {
      required: true,
      validate: (value) =>
        isValidDateOfBirth(value) || 'Date of Birth must be a valid date',
    },
  },
  citizenshipCountry: {
    name: 'citizenshipCountry',
    label: 'Country of Citizenship',
    options: countryOptions,
    validations: {
      required: true,
    },
  },
  residencyCountry: {
    name: 'residencyCountry',
    label: 'Country of Residency',
    options: countryOptions,
    validations: {
      required: true,
    },
  },
  USANationalIdentifier: {
    name: 'nationalIdentifier',
    label: 'Social Security Number',
    placeholder: '###-##-####',
    hasVisibilityToggle: true,
    mask: ssnMask,
    validations: {
      required: true,
      pattern: formattedSSNRegExp,
    },
  },
  nonUSANationalIdentifier: {
    name: 'nationalIdentifier',
    label: 'National Identifier',
    placeholder: 'Government ID/Passport No.',
    validations: {
      required: true,
      maxLength: maxNonUSANationalIdentifierLength,
    },
  },
  jobTitle: {
    name: 'jobTitle',
    label: 'Title / Role',
    validations: {
      required: true,
      maxLength: maxJobTitleLength,
    },
  },
  leaderOfPublicCompany: {
    name: 'leaderOfPublicCompany',
    label: 'I am a leader at a public company',
    helperText:
      'You are a director, officer or hold more than 10% of shares of another company',
  },
  brokerDealer: {
    name: 'brokerDealer',
    label: 'I am a broker-dealer holding assets for other broker-dealers',
    helperText:
      'You are a financial entity who is a buyer and seller of cash and securities for another broker-dealer',
  },
  largeTrader: {
    name: 'largeTrader',
    label: 'I am a Large Trader',
    helperText:
      'You have traded more than 2 million shares/$20 million in a single day, or 20 million shares/$200 million of exchange listed securities in a month',
  },
  stockExchangeFinraMemberAssociated: {
    name: 'stockExchangeFinraMemberAssociated',
    label: 'I am associated with a stock exchange or FINRA member firm',
    helperText:
      'You work for, with, or are affiliated with a FINRA registered firm',
  },
  haveInterestedPartiesToReport: {
    name: 'haveInterestedPartiesToReport',
    label: 'I have interested parties to report',
    helperText:
      'You have a designated third party that should receive duplicate statements and confirmations',
  },
  politicallyExposed: {
    name: 'politicallyExposed',
    label: 'I am a politically exposed person',
    helperText:
      'You are, or are related to a current or former senior political figure',
  },
};
