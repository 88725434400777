import { getVersionedPath, RoutesPath } from 'routes/constants/routes-path';
import {
  Banking,
  ConnectBankAccounts,
  Dashboard,
  Documents,
  Settings,
  Transfer,
} from 'pages/v3-portal';
import {
  BlankLayout,
  V3BlankLayout,
  V3PortalLayout,
  V3PortalLayoutNoFooter,
} from 'layouts';
import { StreamlinedOnboarding } from 'modules/onboarding/v3-streamlined-onboarding';
import { GHBAgreement } from 'modules/migration/ghb-agreement/ghb-agreement';
import { AllocationDemo } from 'modules/portfolio/allocation/components/allocation-demo/allocation-demo';
import { AllocationDemoComparison } from 'modules/portfolio/allocation/components/allocation-demo-comparison/allocation-demo-comparison';
import { TrackPageName } from 'modules/tracking';
import { AllocationTransition } from 'modules/2024-q1/allocation-by-amount-or-percentage/allocation-transition';
import { DepositTransition } from 'modules/2024-q1/schedule-transfer/feature-flag-transition/deposit-transition';
import { WithdrawalTransition } from 'modules/2024-q1/schedule-transfer/feature-flag-transition/withdrawal-transition';
import { Withdrawal } from 'modules/2023-q3/deposit-withdrawal/withdrawal/withdrawal';
import { LoginMFA } from 'modules/2023-q3/login-mfa/login-mfa';
import { DisplayInsightsTab } from 'modules/2023-q3/display-insights-tab/display-insights-tab';
import { AddBankAccountACHInformation } from 'modules/2023-q3/add-bank-account-ach-information/add-bank-account-ach-information';
import { UserRole } from 'modules/2023-q4/rbac/enums/user-role';

export default [
  {
    exact: true,
    path: getVersionedPath({
      path: RoutesPath.onboarding.accountIntake.path,
      version: 3,
    }),
    layout: BlankLayout,
    component: StreamlinedOnboarding,
  },
  {
    exact: true,
    path: RoutesPath.tasks.loginMFA.path,
    layout: V3BlankLayout,
    component: LoginMFA,
    title: TrackPageName.SecurityCheck,
  },
  {
    exact: true,
    path: getVersionedPath({
      path: RoutesPath.onboarding.ghbAgreement.path,
      version: 3,
    }),
    layout: BlankLayout,
    component: GHBAgreement,
  },
  {
    exact: true,
    path: getVersionedPath({
      path: RoutesPath.pages.settings.path,
      version: 3,
    }),
    layout: V3PortalLayoutNoFooter,
    component: Settings,
    title: TrackPageName.Settings,
  },
  {
    exact: true,
    path: getVersionedPath({
      path: RoutesPath.pages.settings.pages.businesses.path,
      version: 3,
    }),
    layout: V3PortalLayoutNoFooter,
    component: Settings,
    title: TrackPageName.Settings,
  },
  {
    exact: true,
    path: getVersionedPath({
      path: RoutesPath.pages.settings.pages.team.path,
      version: 3,
    }),
    layout: V3PortalLayoutNoFooter,
    component: Settings,
    title: TrackPageName.Settings,
    userPermissionsProhibited: [UserRole.WHITE_LABEL],
    userPermissionsRequired: [UserRole.ADMIN],
  },
  {
    exact: true,
    path: getVersionedPath({
      path: RoutesPath.pages.settings.pages.teamInvite.path,
      version: 3,
    }),
    layout: V3PortalLayoutNoFooter,
    component: Settings,
    title: TrackPageName.Settings,
    userPermissionsProhibited: [UserRole.WHITE_LABEL],
    userPermissionsRequired: [UserRole.ADMIN],
  },
  {
    exact: true,
    path: getVersionedPath({
      path: RoutesPath.pages.settings.pages.tradeRestrictions.path,
      version: 3,
    }),
    layout: V3PortalLayoutNoFooter,
    component: Settings,
    title: TrackPageName.Settings,
  },
  {
    exact: true,
    path: getVersionedPath({
      path: RoutesPath.pages.settings.pages.updatePassword.path,
      version: 3,
    }),
    layout: V3PortalLayoutNoFooter,
    component: Settings,
    title: TrackPageName.Settings,
  },
  {
    exact: true,
    path: getVersionedPath({
      path: RoutesPath.pages.settings.pages.quickbooks.path,
      version: 3,
    }),
    layout: V3PortalLayoutNoFooter,
    component: Settings,
    title: TrackPageName.Settings,
  },
  {
    exact: true,
    path: getVersionedPath({ path: RoutesPath.pages.banking.path, version: 3 }),
    layout: V3PortalLayout,
    component: Banking,
    title: 'Banking',
  },
  {
    exact: true,
    path: RoutesPath.pages.connectBankAccountManual.path,
    layout: V3PortalLayout,
    component: AddBankAccountACHInformation,
    title: TrackPageName.ConnectBankAccountManual,
    userPermissionsRequired: [UserRole.ADMIN],
  },
  {
    exact: true,
    path: getVersionedPath({
      path: RoutesPath.pages.connectBankAccounts.path,
      version: 3,
    }),
    layout: V3PortalLayout,
    component: ConnectBankAccounts,
    title: TrackPageName.ConnectBankAccounts,
    userPermissionsRequired: [UserRole.ADMIN],
  },
  {
    exact: true,
    path: getVersionedPath({
      path: RoutesPath.pages.dashboard.path,
      version: 3,
    }),
    layout: V3PortalLayout,
    component: Dashboard,
    title: TrackPageName.Dashboard,
  },
  {
    exact: true,
    path: getVersionedPath({
      path: RoutesPath.pages.onboardingComplete.path, // added for SEO
      version: 3,
    }),
    layout: V3PortalLayout,
    component: Dashboard,
    title: TrackPageName.Dashboard,
  },
  {
    exact: true,
    path: getVersionedPath({
      path: RoutesPath.pages.documents.path,
      version: 3,
    }),
    layout: V3PortalLayout,
    component: Documents,
    title: TrackPageName.Documents,
  },
  {
    exact: true,
    path: getVersionedPath({
      path: RoutesPath.pages.insights.path,
      version: 3,
    }),
    layout: V3PortalLayout,
    component: DisplayInsightsTab,
    title: TrackPageName.Insights,
  },
  {
    exact: true,
    path: RoutesPath.tasks.allocation.path,
    layout: V3BlankLayout,
    component: AllocationTransition,
    title: TrackPageName.PortfolioAllocation,
    userPermissionsRequired: [UserRole.ADMIN],
  },
  {
    exact: true,
    path: RoutesPath.tasks.deposit.path,
    layout: V3BlankLayout,
    component: DepositTransition,
    title: TrackPageName.Deposit,
    userPermissionsRequired: [UserRole.ADMIN],
  },
  {
    exact: true,
    path: RoutesPath.tasks.withdrawal.path,
    layout: V3BlankLayout,
    component: WithdrawalTransition,
    title: TrackPageName.Withdrawal,
    userPermissionsRequired: [UserRole.ADMIN],
  },
  {
    exact: true,
    path: RoutesPath.noAuth.demo.portfolioAllocation.path,
    layout: V3PortalLayout,
    component: AllocationDemo,
    title: TrackPageName.DemoPortfolioAllocation,
    allowNoAuth: true,
  },
  {
    exact: true,
    path: RoutesPath.noAuth.demo.portfolioAllocationComparison.path,
    layout: V3PortalLayout,
    component: AllocationDemoComparison,
    title: TrackPageName.DemoPortfolioAllocationComparison,
    allowNoAuth: true,
  },
];
