import React, { useEffect } from 'react';
import { useTracking, TrackEventName, TrackPageName } from 'modules/tracking';
import { useForm } from 'react-hook-form';
import { useOnboardingContext } from 'modules/onboarding/v3-streamlined-onboarding/streamlined-onboarding';
import {
  formattedDateOfBirth,
  formattedNationalIdentifier,
} from 'modules/onboarding/v3-streamlined-onboarding/pages/components/forms/utils';
import { numberRegex } from 'components/core/forms/password/password-validations';
import { Person } from 'modules/onboarding/v3-streamlined-onboarding/types/person';
import { PersonVariant } from 'modules/onboarding/v3-streamlined-onboarding/enums';
import { formErrorFocus } from 'modules/onboarding/v3-streamlined-onboarding/utils';
import { PageTitle } from '../components/page-title';
import {
  CheckboxController,
  FormFieldContainer,
  SubmitButton,
  formFields,
} from '../components/forms';
import { PersonFormSection } from '../components/forms/sections/person/person-form-section';

export const BusinessRepresentative: React.FC = () => {
  const { trackEvent } = useTracking<{
    page: TrackPageName;
    eventName: TrackEventName;
  }>({
    page: TrackPageName.UserPersonalInfo,
  });

  const onboardingContext = useOnboardingContext();
  const savedOnboardingData = onboardingContext.onboardingResponse.data;
  const savedBusinessRepresentativeData = savedOnboardingData.people?.filter(
    (person: { isPersonOnboarding: boolean }) =>
      person.isPersonOnboarding === true,
  )[0];

  const {
    control,
    formState: { errors, isDirty },
    handleSubmit,
    unregister,
  } = useForm<Person>();

  useEffect(() => {
    formErrorFocus(errors, isDirty);
  }, [errors, isDirty]);

  const onSubmit = (data: Person) => {
    const {
      address1,
      address2,
      city,
      email,
      state,
      zipCode,
      dateOfBirth,
      nationalIdentifier,
      phoneNumber,
      ...rest
    } = data;

    onboardingContext.postOnboardingData({
      businessId: onboardingContext.businessId,
      data: {
        people: [
          {
            ...rest,
            email: savedOnboardingData.personOnboarding?.email,
            dateOfBirth: formattedDateOfBirth(dateOfBirth),
            isPersonOnboarding: true,
            nationalIdentifier: formattedNationalIdentifier(nationalIdentifier),
            phoneNumber: phoneNumber
              ? phoneNumber.match(numberRegex)?.join('')
              : undefined,
            addresses: [
              {
                address1,
                address2,
                city,
                state,
                zipCode,
                id: savedBusinessRepresentativeData?.addresses?.[0]?.id,
              },
            ],
            id: savedBusinessRepresentativeData?.id,
          },
        ],
      },
    });

    trackEvent({
      eventName: TrackEventName.UserPersonalInfoCompleted,
    });

    trackEvent({
      eventName: TrackEventName.AuthorizedSignerStatusUpdated,
    });
  };

  return (
    <>
      <PageTitle
        title="Business representative"
        description="Federal law requires that we obtain, verify, and record information that identifies each person who opens an account. This requires us to collect information that will allow us to identify you. "
      />

      <form onSubmit={handleSubmit(onSubmit)}>
        <FormFieldContainer>
          <CheckboxController
            {...formFields.isAuthorizedSigner}
            control={control}
            defaultValue={
              savedBusinessRepresentativeData.isAuthorizedSigner || false
            }
          />
        </FormFieldContainer>

        <PersonFormSection
          control={control}
          person={savedBusinessRepresentativeData}
          unregister={unregister}
          variant={PersonVariant.Officer}
        />

        <SubmitButton />
      </form>
    </>
  );
};
