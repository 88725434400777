import { WhiteLabelPartners } from 'modules/2023-q4/white-label/consts/white-label-partners';
import { useStoreWhiteLabelPartner } from 'modules/2023-q4/white-label/stores/use-store-white-label-partner';

export const useHideProduct = () => {
  const { partnerId } = useStoreWhiteLabelPartner((state) => state);

  const hideProduct = (product: string) => {
    const partnerData = WhiteLabelPartners.find(
      (partner) => partner.id === partnerId,
    );

    const productsToHide = partnerData?.options?.hideProducts;

    return productsToHide?.[product] || false;
  };

  return { hideProduct };
};
