import React, { useEffect, useState } from 'react';
import { Size } from 'enums';
import { useTracking, TrackEventName, TrackPageName } from 'modules/tracking';
import { useForm } from 'react-hook-form';
import { useOnboardingContext } from 'modules/onboarding/v3-streamlined-onboarding/streamlined-onboarding';
import { Box } from '@material-ui/core';
import { Modal, Spinner, Typography, TypographyVariant } from 'components/core';
import { Person } from 'modules/onboarding/v3-streamlined-onboarding/types/person';
import { PersonVariant } from 'modules/onboarding/v3-streamlined-onboarding/enums';
import {
  FormFieldContainer,
  SubmitButton,
} from 'modules/onboarding/v3-streamlined-onboarding/pages/components/forms';
import { Autocomplete } from 'modules/onboarding/v3-streamlined-onboarding/pages/components/forms/autocomplete';
import { useMutateOnboarding } from 'modules/onboarding/v3-streamlined-onboarding/hooks/mutate-onboarding';
import { PageTitle } from '../components/page-title';
import { PersonRow } from './components/person-row/person-row';
import { PersonModal } from './components/person-modal';
import { BusinessOwnershipQuestion } from './components/business-ownership-question/business-ownership-question';

interface PeopleData {
  id: string;
  isBusinessOwner: boolean;
  isEntityOfficer: boolean;
  ownershipPercentage?: number | null;
}

interface PersonCheckbox {
  [id: string]: {
    Officer: boolean;
    Owner: boolean;
    OwnershipPercentage?: number | null;
  };
}

export const BusinessOwners: React.FC = () => {
  const { trackEvent } = useTracking<{
    page: TrackPageName;
    eventName: TrackEventName;
    personType: PersonVariant;
  }>({
    page: TrackPageName.OwnersAndOfficers,
  });

  const onboardingContext = useOnboardingContext();
  const { isLoading, mutate } = useMutateOnboarding(
    onboardingContext.businessId,
  );
  const { business, people } = onboardingContext.onboardingResponse.data;
  const businessName = business?.legalName;
  const { handleSubmit, register } = useForm();

  const [showBusinessOwners, setShowBusinessOwners] = useState<boolean>();
  const MINIMUM_OWNERSHIP_PERCENTAGE = showBusinessOwners ? 25 : 0;
  const [businessOfficersList, setBusinessOfficersList] = useState<Person[]>(
    people.filter((person: PeopleData) => person.isEntityOfficer),
  );
  const [businessOwnersList, setBusinessOwnersList] = useState<Person[]>(
    people.filter((person: PeopleData) => person.isBusinessOwner),
  );
  const [autocompleteValue, setAutocompleteValue] = useState<string>();

  const [personModalOpen, setPersonModalOpen] = useState(false);
  const [currentPerson, setCurrentPerson] = useState<Person>();
  const [currentVariant, setCurrentVariant] = useState(PersonVariant.Owner);
  const [showMinimumOfficerError, setShowMinimumOfficerError] = useState(false);
  const [showOwnerError, setShowOwnerError] = useState(false);

  const getPeopleOptions = (variant: PersonVariant) => {
    const isOwner = variant === PersonVariant.Owner;
    const addLabel = isOwner ? 'Add business owner' : 'Add business officer';

    const options = [...people]
      .filter((person) =>
        isOwner
          ? !businessOwnersList.includes(person)
          : !businessOfficersList.includes(person),
      )
      ?.sort((a: { firstName: string }, b: { firstName: string }) =>
        a.firstName.toLowerCase().localeCompare(b.firstName.toLowerCase()),
      )
      .map?.((item: { firstName: string; id: string; lastName: string }) => {
        return { label: `${item.firstName} ${item.lastName}`, value: item.id };
      });

    return [{ label: addLabel, value: '' }, ...options];
  };

  const handleAddPerson = (variant: PersonVariant) => {
    setCurrentPerson(undefined);
    setCurrentVariant(variant);
    setPersonModalOpen(true);

    trackEvent({
      eventName:
        variant === PersonVariant.Owner
          ? TrackEventName.CreateNewOwnerClicked
          : TrackEventName.CreateNewOfficerClicked,
    });
  };

  const handleEditPerson = (person: Person, variant: PersonVariant) => {
    setCurrentPerson(person);
    setCurrentVariant(variant);
    setPersonModalOpen(true);

    trackEvent({
      eventName: TrackEventName.PersonEdited,
      personType: variant,
    });
  };

  const handleRemovePerson = (person: Person, variant: PersonVariant) => {
    const isOwner = variant === PersonVariant.Owner;

    setCurrentVariant(variant);
    if (isOwner) {
      mutate({
        businessId: onboardingContext.businessId,
        data: {
          people: [
            {
              id: person.id,
              isBusinessOwner: false,
            },
          ],
        },
      });
    } else {
      mutate({
        businessId: onboardingContext.businessId,
        data: {
          people: [
            {
              id: person.id,
              isEntityOfficer: false,
            },
          ],
        },
      });
    }

    trackEvent({
      eventName: TrackEventName.PersonDeleted,
      personType: variant,
    });
  };

  const resetMinimumError = (variant: PersonVariant) => {
    if (variant === PersonVariant.Officer) {
      setShowMinimumOfficerError(false);
    }

    if (variant === PersonVariant.Owner) {
      setShowOwnerError(false);
    }
  };

  const handleOnPersonChange = (event: any, variant: PersonVariant) => {
    const isOwner = variant === PersonVariant.Owner;
    const personId = event?.target?.value;

    // setting key of Autocomplete to force placeholder after onChange
    // otherwise it will show last value
    const randomString = (Math.random() + 1).toString(36).substring(7);
    setAutocompleteValue(randomString);

    if (personId) {
      setCurrentVariant(variant);
      if (isOwner) {
        const owner = people.find((person: Person) => person.id === personId);
        let ownershipPercentage = null;

        if (
          owner &&
          owner?.ownershipPercentage >= MINIMUM_OWNERSHIP_PERCENTAGE
        ) {
          ownershipPercentage = owner?.ownershipPercentage;
        }

        mutate({
          businessId: onboardingContext.businessId,
          data: {
            people: [
              {
                id: personId,
                isBusinessOwner: true,
                ownershipPercentage,
              },
            ],
          },
        });
      } else {
        mutate({
          businessId: onboardingContext.businessId,
          data: {
            people: [
              {
                id: personId,
                isEntityOfficer: true,
              },
            ],
          },
        });
      }

      resetMinimumError(variant);

      return;
    }

    handleAddPerson(variant);
  };

  const onSubmit = (data: PersonCheckbox) => {
    const peopleData: PeopleData[] = [];

    // PD-1088 If a business has any owners, check for at least one owner,
    // each owner has an ownership percentage, and at least one officer
    //
    // If the business does not have any owners, make each officer an owner
    // with 0 ownership percentage

    // set all isBusinessOwner to false
    // set all isEntityOfficer to false
    // set all ownershipPercentage to null
    people.map((person: PeopleData) => {
      peopleData.push({
        id: person.id,
        isBusinessOwner: false,
        isEntityOfficer: false,
        ownershipPercentage: null,
      });

      return false;
    });

    if (showBusinessOwners) {
      businessOwnersList.map((person) => {
        const target = peopleData.find((pd) => pd.id === person.id);

        if (target) {
          target.isBusinessOwner = true;
          target.ownershipPercentage = people.find(
            (personFromDatabase: PeopleData) =>
              personFromDatabase.id === person.id,
          )?.ownershipPercentage;
        }

        return false;
      });

      businessOfficersList.map((person) => {
        const target = peopleData.find((pd) => pd.id === person.id);

        if (target) {
          target.isEntityOfficer = true;
        }

        return false;
      });
    } else {
      businessOfficersList.map((person) => {
        const target = peopleData.find((pd) => pd.id === person.id);

        if (target) {
          target.isBusinessOwner = true;
          target.isEntityOfficer = true;
          target.ownershipPercentage = 0;
        }

        return false;
      });
    }

    // error checks
    const validBusinessOwner = (person: PeopleData) =>
      person.isBusinessOwner === true &&
      person.ownershipPercentage !== undefined &&
      person.ownershipPercentage !== null &&
      person.ownershipPercentage >= MINIMUM_OWNERSHIP_PERCENTAGE;

    const hasAtLeastOneOfficer = peopleData.some(
      (person) => person.isEntityOfficer === true,
    );

    const hasAtLeastOneOwner = peopleData.some((person) =>
      validBusinessOwner(person),
    );

    const allOwnersHaveOwnership = peopleData
      .filter((person) => person.isBusinessOwner)
      .every((owner) => validBusinessOwner(owner));

    if (
      !hasAtLeastOneOfficer ||
      !hasAtLeastOneOwner ||
      !allOwnersHaveOwnership
    ) {
      setShowMinimumOfficerError(!hasAtLeastOneOfficer);
      setShowOwnerError(!hasAtLeastOneOwner || !allOwnersHaveOwnership);

      return;
    }

    onboardingContext.postOnboardingData({
      businessId: onboardingContext.businessId,
      data: {
        people: peopleData,
      },
    });

    trackEvent({
      eventName: TrackEventName.OwnersAndOfficersCompleted,
    });
  };

  useEffect(() => {
    // if a person is edited or added, update the business owners and business officers lists
    setBusinessOfficersList(
      people.filter((person: PeopleData) => person.isEntityOfficer),
    );
    setBusinessOwnersList(
      people.filter(
        (person: PeopleData) =>
          person.isBusinessOwner && person?.ownershipPercentage !== 0,
      ),
    );
  }, [people]);

  useEffect(() => {
    // if an owner doesn't have an ownership percentage, open the edit modal
    const person = businessOwnersList.find(
      (owner: Person) => owner.ownershipPercentage === null,
    );

    if (person) {
      handleEditPerson(person, PersonVariant.Owner);
    }
  }, [businessOwnersList]);

  return (
    <>
      <PageTitle
        title="Business owners and officers"
        description="Due to regulatory guidelines, we're required to collect information on anyone who has significant ownership or control of your business."
      />

      <Box mt={5}>
        <BusinessOwnershipQuestion
          setShowBusinessOwners={setShowBusinessOwners}
        />
      </Box>

      <form onSubmit={handleSubmit(onSubmit)}>
        {showBusinessOwners && (
          <>
            <Box mb={2}>
              <Typography
                color={showOwnerError ? 'amaranth' : 'nero'}
                variant={TypographyVariant.LargerLabel}
              >
                Add all individuals with ownership of{' '}
                {MINIMUM_OWNERSHIP_PERCENTAGE}% or more.
              </Typography>
            </Box>

            <FormFieldContainer>
              <Autocomplete
                disableClearable
                key={autocompleteValue}
                options={getPeopleOptions(PersonVariant.Owner)}
                onChange={(
                  event: React.ChangeEvent<{
                    name?: string | undefined;
                    value: unknown;
                  }>,
                ) => handleOnPersonChange(event, PersonVariant.Owner)}
                placeholder="Select or add a new business owner"
              />
            </FormFieldContainer>

            <Box mb={5}>
              {isLoading && currentVariant === PersonVariant.Owner ? (
                <Spinner />
              ) : (
                businessOwnersList.map((person: Person) => (
                  <PersonRow
                    editPerson={() =>
                      handleEditPerson(person, PersonVariant.Owner)
                    }
                    key={person.id}
                    person={person}
                    register={register}
                    removePerson={() =>
                      handleRemovePerson(person, PersonVariant.Owner)
                    }
                    resetMinimumError={resetMinimumError}
                    variant={PersonVariant.Owner}
                  />
                ))
              )}
            </Box>
          </>
        )}

        {showBusinessOwners !== undefined && (
          <>
            <Box mb={2}>
              <Typography
                color={showMinimumOfficerError ? 'amaranth' : 'nero'}
                variant={TypographyVariant.LargerLabel}
              >
                {showBusinessOwners
                  ? 'Add at least 1 officer with leadership responsibilities and roles such as president, CEO, VP, secretary, or treasurer.'
                  : `Add at least one c-level executive${
                      businessName ? ` of ${businessName}` : ''
                    }.`}
              </Typography>
            </Box>

            <FormFieldContainer>
              <Autocomplete
                disableClearable
                key={autocompleteValue}
                options={getPeopleOptions(PersonVariant.Officer)}
                onChange={(
                  event: React.ChangeEvent<{
                    name?: string | undefined;
                    value: unknown;
                  }>,
                ) => handleOnPersonChange(event, PersonVariant.Officer)}
                placeholder="Select or add a new business officer"
              />
            </FormFieldContainer>

            <Box mb={10}>
              {isLoading && currentVariant === PersonVariant.Officer ? (
                <Spinner />
              ) : (
                businessOfficersList.map((person: Person) => (
                  <PersonRow
                    editPerson={() =>
                      handleEditPerson(person, PersonVariant.Officer)
                    }
                    key={person.id}
                    person={person}
                    register={register}
                    removePerson={() =>
                      handleRemovePerson(person, PersonVariant.Officer)
                    }
                    resetMinimumError={resetMinimumError}
                    variant={PersonVariant.Officer}
                  />
                ))
              )}
            </Box>

            <SubmitButton />
          </>
        )}
      </form>

      <Modal
        hideCloseIcon
        open={personModalOpen}
        onClose={() => setPersonModalOpen(false)}
        size={Size.Small}
        disableBackdropClick={true}
        disableEscapeKeyDown={true}
      >
        <PersonModal
          onClose={() => setPersonModalOpen(false)}
          person={currentPerson}
          resetMinimumError={resetMinimumError}
          variant={currentVariant}
        />
      </Modal>
    </>
  );
};
